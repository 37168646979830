import "../scss/main.scss";
import gallery_descriptions from "../assets/gallery_descriptions.js";
import "../favicon.ico";

const nav = document.querySelector("nav");
const side_nav = document.querySelector(".side_nav");
const hamburger = document.querySelector(".hamburger-btn");

const gallery_images = document.querySelectorAll(".gallery_image");
const gallery_modal = document.querySelector(".gallery_modal");
const close_gallery = document.querySelector(".close_modal");
const left_click = document.querySelector(".left_click");
const right_click = document.querySelector(".right_click");
const contact_form = document.querySelector(".contact_form");

let slideIndex = 1;
let dataset_rel = "";

document.addEventListener("DOMContentLoaded", () => {
  hamburger.addEventListener("click", () => {
    hamburger.classList.toggle("active");
    if (hamburger.classList.contains("active")) {
      side_nav.classList.add("active");
      nav.style.backgroundColor = "rgba(19, 13, 9, 0.925)";
    } else {
      side_nav.classList.remove("active");
      if (window.scrollY <= 50) {
        nav.style.backgroundColor = "rgba(19, 13, 9, 0.0)";
      }
    }
  });

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 50) {
      nav.style.backgroundColor = "rgba(19, 13, 9, 0.925)";
    } else {
      nav.style.backgroundColor = "rgba(19, 13, 9, 0.0)";
    }
  });

  side_nav.addEventListener("click", () => {
    side_nav.classList.remove("active");
    hamburger.classList.remove("active");
  });

  // galley modal
  gallery_images.forEach(image => {
    image.addEventListener("click", () => {
      gallery_modal.classList.add("visible");
      slideIndex = 1;
      dataset_rel = image.dataset.rel;
      gallery_modal.children[0].children[0].children[0].setAttribute(
        "src",
        `./img/rel${dataset_rel}_${slideIndex}.jpg`
      );
      document.querySelector(".modal_title").textContent =
        gallery_descriptions[dataset_rel].title;
      document.querySelector(".modal_desc").innerHTML =
        gallery_descriptions[dataset_rel].desc;
    });
  });

  left_click.addEventListener("click", () => {
    slideIndex -= 1;
    if (slideIndex > 3) {
      slideIndex = 1;
    }
    if (slideIndex < 1) {
      slideIndex = 3;
    }
    gallery_modal.children[0].children[0].children[0].setAttribute(
      "src",
      `./img/rel${dataset_rel}_${slideIndex}.jpg`
    );
    console.log(slideIndex);
  });
  right_click.addEventListener("click", () => {
    slideIndex += 1;
    if (slideIndex > 3) {
      slideIndex = 1;
    }
    if (slideIndex < 1) {
      slideIndex = 3;
    }
    gallery_modal.children[0].children[0].children[0].setAttribute(
      "src",
      `./img/rel${dataset_rel}_${slideIndex}.jpg`
    );
  });

  close_gallery.addEventListener("click", () => {
    gallery_modal.classList.remove("visible");
  });

  $(".contact_form").submit(function(e) {
    e.preventDefault();

    var $form = $(this);
    $.post($form.attr("action"), $form.serialize()).then(function() {
      alert("Dzięki za wiadomość!");
    });
  });
});
