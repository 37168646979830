export default {
    "01": {
        "title": "Częstochowa",
        "desc": ""
    },
    "02": {
        "title": "Kalisz",
        "desc": "Wykonaliśmy usługi z zakresu montażu: <br>-więźby dachowej <br>-deskowania wraz z mechanicznym montażem papy <br>-dachówki Roben MonzaPlus <br>-5 okien dachowych Velux <br>-podbitki dachowej"
    },
    "03": {
        "title": "Kurów",
        "desc": "Wykonaliśmy usługi z zakresu montażu:<br> -Pokrycia dachowego blachodachówką Ruukki Hyygge<br> -podbitki dachowej Ruukki Soffit"
    },
    "04": {
        "title": "Lututów",
        "desc": "Wykonaliśmy usługi z zakresu montażu:<br>- pokrycia dachowego, blachodachówką Ruukki Finera.<br>- podbitki dachowej"
    },
    "05": {
        "title": "Lututów",
        "desc": ""
    },
    "06": {
        "title": "Lututów",
        "desc": "Wykonaliśmy usługi z zakresu:<br>-Wydłużenia połaci dachu <br>-Ułożenia membrany dachowej Avalaine titanium wraz z łatowaniem <br>-Montażu struktonitu, systemu rynnowego Flamingo oraz obróbek blacharskich <br>-wzniesienia przewodów dymnych i wentylacyjnych (kominów)<br>-ułożenia blachodachówki modułowej Ruukki Finera<br>-montażu zadaszenia nad wejściem oraz tarasem"
    },
    "07": {
        "title": "Pątnów",
        "desc": ""
    },
    "08": {
        "title": "Praszka",
        "desc": ""
    },
    "09": {
        "title": "Sokolniki",
        "desc": ""
    },
    "10": {
        "title": "Wieluń",
        "desc": ""
    },
    "11": {
        "title": "Wieluń ul. Fabryczna",
        "desc": "Wykonaliśmy usługi z zakresu montażu: <br>-więźby dachowej <br>-pełnego deskowania wraz z mechanicznym montażem papy <br>-panela 'click' Planja Emka <br>-sześciu okien dachowych 'dakea'"
    },
    "12": {
        "title": "Wierzchlas",
        "desc": ""
    }
}